import React, { useEffect, useState } from "react";

export default function Prix({defaultPrix, setNotSave, component}) {
    const [diplayPrice, setDisplayPrice] = useState("")

    useEffect(() => {
        if (defaultPrix) {
            setDisplayPrice(defaultPrix/100)
        }
    }, [defaultPrix])

    function priceConverssion(price){
        price = price.replace(" ", "")
        if (price.includes(",")) {
            price = price.replace(",", ".")
        }
        if (price.includes(".")) {
            var output = ""
            var split = price.split(".")
            if (split.length > 2) {
                component.data.options[0].value = "error"
                return
            }
            output = split[0]
            if (split[1].length >= 2) {
                output += split[1].slice(0, 2)
            } else if (split[1].length === 1) {
                output += split[1] + "0"
            } else {
                output += "00"
            }
            component.data.options[0].value = output
            setNotSave(true)
        } else {
            component.data.options[0].value = (price*100).toString()
            setNotSave(true)
        }
    }
    return (<input type="number"
        onChange={(e) => { priceConverssion(e.target.value) }}
        defaultValue={diplayPrice} placeholder={"€"}
        className="h-8 px-3 bg-transparent border border-gray-300 rounded w-full text-right" />)
}