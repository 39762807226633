import React, { useEffect, useState } from "react";
import LoadingSpinner from "../Animation/LoadingSpinner";
import { withTranslation } from "react-i18next";
import Switch from "./Switch";

const UTClist = ["-12", "-11", "-10", "-9", "-8", "-7", "-6", "-5", "-4", "-3", "-2", "-1", "+0", "+1", "+2", "+3", "+4", "+5", "+6", "+7", "+8", "+9", "+10", "+11", "+12", "+13", "+14"]

function InfoTimeInputs({ t, value, setValue, loading, disabled }) {
    const [timedFormToggle, setTimedFormToggle] = useState(false)
    const [softToggle, setSoftToggle] = useState(false)
    const [startDate, setStartDate] = useState("")
    const [startTime, setStartTime] = useState("")
    const [endDate, setEndDate] = useState("")
    const [endTime, setEndTime] = useState("")
    const [softDate, setSoftDate] = useState("")
    const [softTime, setSoftTime] = useState("")
    const [UTC, setUTC] = useState("+2")

    useEffect(() => {
        if (value) {
            if (value.start || value.end || value.soft) {
                let UTClocal = "+2"
                const splitDateTime = (dateTime) => {
                    const [date = '', timeWithUTC = ''] = dateTime.split('T');
                    const match = timeWithUTC.match(/([+-]\d{2}:\d{2})$/);
                    const time = match ? timeWithUTC.replace(match[1], '') : timeWithUTC;
                    const utc = match ? match[1] : '';
                    return { date, time, utc };
                };
                if (value.start) {
                    const { date, time, utc } = splitDateTime(value.start);
                    setStartDate(date);
                    setStartTime(time);
                    UTClocal = utc;
                }
                if (value.end) {
                    const { date, time, utc } = splitDateTime(value.end);
                    setEndDate(date);
                    setEndTime(time);
                    UTClocal = utc;
                }
                if (value.soft) {
                    const { date, time, utc } = splitDateTime(value.soft);
                    setSoftDate(date);
                    setSoftTime(time);
                    UTClocal = utc;
                    setSoftToggle(true);
                }
                if (UTClocal) {
                    UTClocal = UTClocal.split(':')[0]
                } else {
                    UTClocal = "+2"
                }
                if (UTClocal[1] === '0') {
                    setUTC(UTClocal[0] + UTClocal[2])
                } else {
                    setUTC(UTClocal)
                }
                setTimedFormToggle(true)
            }
        }
    }, [value])

    function onSwitchChange() {
        const valueUpdated = !timedFormToggle;
        if (value === null) {
            setValue({ start: "", end: "", soft: "" })
        } if (valueUpdated) {
            updateValue()
        } else {
            setValue({ start: "", end: "", soft: "" })
        }
        setTimedFormToggle(valueUpdated);
    }

    function updateValue(value, toUpdate) {
        let start = ""
        let end = ""
        let soft = ""
        let UTCLocal = ""
        if (toUpdate === "UTC") {
            UTCLocal = value
        } else {
            UTCLocal = UTC
        }
        if (UTCLocal.length === 2) {
            UTCLocal = UTCLocal[0] + '0' + UTCLocal[1]
        } if (!UTCLocal.includes(':')) {
            UTCLocal = UTCLocal + ":00"
        }
        if (startDate && startTime && toUpdate !== "start") {
            start = startDate + "T" + startTime + UTCLocal
        } if (endDate && endTime && toUpdate !== "end") {
            end = endDate + "T" + endTime + UTCLocal
        } if ((softToggle || toUpdate === "forceSoft") && softDate && softTime && toUpdate !== "soft") {
            soft = softDate + "T" + softTime + UTCLocal
        }
        if (value) {
            if (value.includes('-')) {
                if (toUpdate === "start") {
                    start = value + "T" + startTime + UTCLocal
                } else if (toUpdate === "end") {
                    end = value + "T" + endTime + UTCLocal
                } else if (toUpdate === "soft") {
                    soft = value + "T" + softTime + UTCLocal
                }
            } else if (value.includes(':')) {
                if (toUpdate === "start") {
                    start = startDate + "T" + value + UTCLocal
                } else if (toUpdate === "end") {
                    end = endDate + "T" + value + UTCLocal
                } else if (toUpdate === "soft") {
                    soft = softDate + "T" + value + UTCLocal
                }
            }
        }
        // console.log("updateValue")
        // console.log({ start, end, soft })
        setValue({ start, end, soft })
    }

    function softCloseToggle(cls) {
        setSoftToggle(cls)
        if (!cls) {
            let locoValue = value
            locoValue.soft = ""
            setValue(value)
        } else {
            updateValue("", "forceSoft")
        }
    }

    return loading ? (<LoadingSpinner />) : (
        <>
            <button disabled={disabled} type="button" onClick={onSwitchChange} aria-pressed="false" aria-labelledby="renew-headline" className={
                timedFormToggle ?
                    "bg-primary-600 disabled:bg-gray-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-1000"
                    : "bg-gray-200 disabled:bg-gray-100 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-1000"
            } >
                <span aria-hidden="true" className={timedFormToggle ?
                    "translate-x-5 inline-block h-5 w-5 rounded-full bg-primary-200 shadow transform ring-0 transition ease-in-out duration-1000"
                    : "translate-x-0 inline-block h-5 w-5 rounded-full bg-slate-50 shadow transform ring-0 transition ease-in-out duration-1000"}>
                    <svg xmlns="http://www.w3.org/2000/svg" style={timedFormToggle ? { animation: "spin 1s ease-in-out" } : {}} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                </span>
            </button >
            {timedFormToggle ?
                <div>
                    <fieldset>
                        <legend className="block text-sm font-medium leading-6 text-gray-800 mt-3">{t("Start date")}</legend>
                        <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm w-full">
                            <div className="flex -space-x-px">
                                <div className="w-1/2 min-w-0 flex-1">
                                    <label htmlFor="start-date" className="sr-only">
                                        start-date
                                    </label>
                                    <input
                                        id="start-date"
                                        // name="start-date"
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => { setStartDate(e.target.value); updateValue(e.target.value, "start") }}
                                        className="px-2 relative block w-full rounded-none rounded-l-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="start-time" className="sr-only">
                                        start-time
                                    </label>
                                    <input
                                        id="start-time"
                                        // name="start-time"
                                        type="time"
                                        value={startTime}
                                        onChange={(e) => { setStartTime(e.target.value); updateValue(e.target.value, "start") }}
                                        // step="900"//lol
                                        className="px-2 relative block w-full rounded-none border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="min-w-0 flex-1 relative">
                                    <label htmlFor="UTC1" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
                                        UTC
                                    </label>
                                    <select
                                        className="px-2 block w-18 h-full rounded-none rounded-r-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => { setUTC(e.target.value); updateValue(e.target.value, "UTC") }}
                                        value={UTC}
                                        id="UTC1"
                                        // name="UTC"
                                    >
                                        {UTClist.map(e =>
                                            <option key={e} value={e} style={{ fontSize: "14px", padding: "0 10px" }}>{e}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend className="block text-sm font-medium leading-6 text-gray-800 mt-3">{t("Close date")}</legend>
                        <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm w-full">
                            <div className="flex -space-x-px">
                                <div className="w-1/2 min-w-0 flex-1">
                                    <label htmlFor="close-date" className="sr-only">
                                        close-date
                                    </label>
                                    <input
                                        id="close-date"
                                        // name="close-date"
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => { setEndDate(e.target.value); updateValue(e.target.value, "end") }}
                                        className="px-2 relative block w-full rounded-none rounded-l-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="close-time" className="sr-only">
                                        close-time
                                    </label>
                                    <input
                                        id="close-time"
                                        // name="close-time"
                                        type="time"
                                        value={endTime}
                                        onChange={(e) => { setEndTime(e.target.value); updateValue(e.target.value, "end") }}
                                        // step="900"//lol
                                        className="px-2 relative block w-full rounded-none border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="min-w-0 flex-1 relative">
                                    <label htmlFor="UTC1" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
                                        UTC
                                    </label>
                                    <select
                                        className="px-2 block w-18 h-full rounded-none rounded-r-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => { setUTC(e.target.value); updateValue(e.target.value, "UTC") }}
                                        value={UTC}
                                        id="UTC2"
                                        // name="UTC"
                                    >
                                        {UTClist.map(e =>
                                            <option key={e} value={e} style={{ fontSize: "14px", padding: "0 10px" }}>{e}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        {/* <div className="flex mt-3">//TODO retirer le commentaire pour activer le soft close
                            <legend className="block text-sm font-medium leading-6 text-gray-800 mr-10">{t("Soft close date")}</legend>
                            <Switch value={softToggle} onClick={softCloseToggle} />
                        </div> */}
                        {softToggle ? <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm w-full">
                            <div className="flex -space-x-px">
                                <div className="w-1/2 min-w-0 flex-1">
                                    <label htmlFor="soft-date" className="sr-only">
                                        soft-date
                                    </label>
                                    <input
                                        id="soft-date"
                                        name="soft-date"
                                        type="date"
                                        value={softDate}
                                        onChange={(e) => { setSoftDate(e.target.value); updateValue(e.target.value, "soft") }}
                                        className="px-2 relative block w-full rounded-none rounded-l-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="soft-time" className="sr-only">
                                        soft-time
                                    </label>
                                    <input
                                        id="soft-time"
                                        name="soft-time"
                                        type="time"
                                        value={softTime}
                                        onChange={(e) => { setSoftTime(e.target.value); updateValue(e.target.value, "soft") }}
                                        // step="900"//lol
                                        className="px-2 relative block w-full rounded-none rounded-r-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="UTC3" className="sr-only">
                                        UTC
                                    </label>
                                    <select
                                        className="px-2 relative block w-18 h-full rounded-none rounded-r-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => { setUTC(e.target.value); updateValue(e.target.value, "UTC") }}
                                        value={UTC}
                                        id="UTC3"
                                        name="UTC"
                                    >
                                        {UTClist.map(e =>
                                            <option key={e} value={e} style={{ fontSize: "14px", padding: "0 10px" }}>{e}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                            : <></>}
                    </fieldset>
                </div>
                : <></>}
        </>
    )
}
export default withTranslation()(InfoTimeInputs);