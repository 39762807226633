import React, { useEffect, useReducer, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Card from "../../../components/Card/Card";
import FakeCard from "../../../components/Fake/FakeCard";
import Structure from "../../../components/Forms/Structure";
import TextArea from "../../../components/Inputs/TextArea";
import Center1Column from "../../../layout/Page/Center/1column";
import { createCandidature } from "../../../utils/requests/candidature";
import { CheckUnicityConflict, formData, GenerateLightForms, getCurentUser, getFormTypes, tenantLongData, updateInfoForm } from "../../../utils/requests/cosmo";
import DeleteModal from "../../../components/Popup/deleteModal";
import InfoModal from "../../../components/Popup/infoModal";
import { getAccessToken } from "../../../auth/verifyAuth";
import { v4 as uuidv4 } from 'uuid';
import Banner from "../../Theme/Component/Banner";
import InfoTimeInputs from "../../../components/Inputs/InfoTimeInputs";
// import { Switch } from "@headlessui/react";

const level = ["Brevet", "Bac", "Bac+1", "Bac+2", "Bac+3", "Bac+4", "Bac+5", "Bac+6", "Bac+7"];


function Informations({ t }) {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [informations, setInformations] = useState({ "name": "", "description": "" });
  // const [pipeline, setPipeline] = useState({});
  const [pipelineId, setPipelineId] = useState("");
  const [pipelineStart, setPipelineStart] = useState("");
  const [pipelineEnd, setPipelineEnd] = useState("");
  const [pipelineStages, setPipelineStages] = useState([]);
  const [pipelineList, setPipelineList] = useState([]);
  const [candidature, setCandidature] = useState({});
  const [deleteButton, setDeleteButton] = useState(false);
  const [copyButton, setCopyButton] = useState(false);
  const [userRights, setUserRights] = useState({})
  const [formTypes, setFormTypes] = useState([])
  const [, forceRerender] = useReducer(x => x + 1, 0)
  const [informationsList, setInformationsList] = useState([])
  const [errors, setErrors] = useState([])
  const [warnings, setWarnings] = useState([])
  const [time, setTime] = useState(null)

  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getCurentUser().then(curentUser => {
      setUserRights(curentUser.rights)
      if (id === "new") {
        setCandidature({});
        setInformations({});
        tenantLongData().then(tenant => {
          setPipelineList(JSON.parse(tenant.hubspot.pipelines).results)
          setInformationsList(tenant.hubspot.information_list)
          setLoading(false);
        })
      } else {
        formData(id).then(cand => {
          setCandidature(cand);
          let preParameters = {};
          cand.Parameters?.forEach(param => {
            preParameters[param.name] = param.value;
          })
          setInformations(preParameters);
          // setPipeline(cand.Pipeline);
          setPipelineId(cand.Pipeline.id);
          setPipelineStart(cand.Pipeline.start);
          setPipelineEnd(cand.Pipeline.end);
          setTime(cand?.ActivationDateTime);
          tenantLongData().then(tenant => {
            setInformationsList(tenant.hubspot.information_list)
            // console.log(tenant.hubspot.information_list)
            let pipe = JSON.parse(tenant.hubspot.pipelines).results
            setPipelineList(pipe)
            if (cand.Pipeline.id) {
              pipe.forEach(pip => {
                if (pip.id === cand.Pipeline.id) {
                  setPipelineStages(pip.stages)
                }
              });
            }
            setLoading(false);
          })
        })
        unicityConflict()
      }
    })
    getFormTypes().then(formTypesLoc => {
      setFormTypes(formTypesLoc)
    })

  }, [id]);

  function unicityConflict() {
    CheckUnicityConflict(id).then(conflictList => {
      if (conflictList.length > 0) {
        setErrors([t("Conflict with ") + conflictList.length + t(" forms")])
        // setErrors(oldErrors => [...oldErrors, t("Conflict with ") + conflictList.length + t(" forms")])
      } else {
        setErrors([])//tmp
      }
  })
  }

  function formSave(event) {
    event.preventDefault()
    let informationObject = {}
    for (let i in informationsList) {
      informationObject[informationsList[i].name] = informationsList[i]
    }
    let output = []
    for(let t = 0; event.target[t]; t++) {
      if (event.target[t].name) {
        if (event.target[t].name in informationObject) {
          output.push({name : event.target[t].name, value: event.target[t].value, display: informationObject[event.target[t].name].display, filter: informationObject[event.target[t].name].filter, description: informationObject[event.target[t].name].description, unicity: informationObject[event.target[t].name].unicity});
        } else {
          output.push({name : event.target[t].name, value: event.target[t].value});
        }
      }
    }
    saveChange(output)
    unicityConflict()
  }

  function saveChange(param) {
    setUploading(true);
    if (id !== "new") {
      let cand = candidature;
      cand.Pipeline.id = pipelineId
      cand.Pipeline.start = pipelineStart
      cand.Pipeline.end = pipelineEnd
      cand.ActivationDateTime = time
      // let newParam = []
      // const tmpBlackList = ["entry_level", "duration", "languages", "campus", "curriculum", "start_academic_year"]
      // for (const [key, value] of Object.entries(informations)) {
      //   if (!tmpBlackList.includes(key)) {
      //     newParam.push({name : key, value: value});
      //   }
      // }
      // cand.Parameters = informations;
      cand.Parameters = param;
      toast.promise(() => updateInfoForm(cand).then(res => {
        setUploading(false);
        if (res.ok) {
          return res.json();
        }
      }).then(candidAPI => {
        GenerateLightForms();
        if (candidAPI?.parameters) {
          setInformations(candidAPI.parameters);
        }
      }), {
        pending: t("Updating informations"),
        success: t("Form successfully updated !"),
        error: t("An error occured. Please retry later.")
      })

    } else {
      let cand = {}
      cand.Parameters = param
      cand.Pipeline = {}
      cand.Pipeline.id = pipelineId
      cand.Pipeline.start = pipelineStart
      cand.Pipeline.end = pipelineEnd
      cand.ActivationDateTime = time
      createCandidature(cand).then(data => {
        setUploading(false);
        navigate("/" + data.id + "/data")
        GenerateLightForms();
      })
    }

  }

  const handleChange = (field, value) => {
    let updatedValue = {};
    updatedValue[field] = value;
    setInformations(informations => ({
      ...informations,
      ...updatedValue
    }))
  }

  // function handleOnChange(field, value) {
  //   // let informationsLocal = JSON.parse(JSON.stringify(informations))
  //   let informationsLocal = informations
  //   informationsLocal[field] = value;
  //   setInformations(informationsLocal);
  //   console.log(informations)
  //   // forceRerender();
  // }

  function SelectPipeline(event) {
    setPipelineId(event.target.value)
    pipelineList.forEach(pip => {
      if (pip.id === event.target.value) {
        setPipelineStages(pip.stages)
      }
    });
  }

  function DeleteForm() {
    getAccessToken().then((accessToken) => {
      fetch(window.location.origin + "/api/v1.0/cosmos/removeForm", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "my-Authorization": `Bearer ${accessToken}`
        },
        body: id
      }).then(setTimeout(() => { GenerateLightForms().then(navigate("/")) }, 2000))
    })
  }

  function infoTypeSelector(info) {
    switch (info.fieldType) {
      case "booleancheckbox":
      case "checkbox":
      case "radio":
      case "select":
        return <Structure key={uuidv4()} id={"struct-" + info.name} title={info.label} component={() =>
          // <Select value={informations[info.name]} options={info.options.map(e => e.label)} onClick={(value) => {
          //   handleOnChange(info.name, value);
          // }} />
          <select id={info.name} name={info.name} className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3" defaultValue={informations[info.name]} onInput={(e) => handleChange(info.name, e.target.value)} >
            <option key={""}></option>
            {info.options.map(opt => <option value={opt.value} key={opt.value}>{opt.label}</option>)}
          </select>
        } />
      case "date":
        return <Structure key={uuidv4()} id={"struct-" + info.name} title={info.label} component={() => <input id={info.name} name={info.name} defaultValue={informations[info.name]} type="date" onBlur={(e) => handleChange(info.name, e.target.value)} className="border w-60 block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"/>} />
      case "phonenumber":
        return <Structure key={uuidv4()} id={"struct-" + info.name} title={info.label} component={() => <input id={info.name} name={info.name} defaultValue={informations[info.name]} type="tel" onBlur={(e) => handleChange(info.name, e.target.value)} className="border w-60 block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"/>}/>
      default:
        return <Structure key={uuidv4()} id={"struct-" + info.name} title={info.label} component={() => <input id={info.name} name={info.name} defaultValue={informations[info.name]} onBlur={(e) => handleChange(info.name, e.target.value)} className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"/>} />
    }
  }

  return (<Center1Column>
    {errors.map(error => <Banner text={error}/> )}
    {!loading ? <Card title={t("Informations")} noPadding>
      <form onSubmit={formSave}>
        <Structure id="name" title={t("Name")} component={() => <input name={"name"} defaultValue={informations.name} className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"/>} />
        <Structure id="formType" title={t("Form Type")} component={() =>
                <select
                  name="formType"
                  className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"
                  onInput={(e) => handleChange("formType", e.target.value)}
                  value={informations.formType}
                >
                  <option key={""} value={""} style={{ fontSize: "14px", padding: "0 10px" }}></option>
                  {formTypes.map(e =>
                    <option key={e.id} value={e.id} style={{ fontSize: "14px", padding: "0 10px" }}>{e.name}</option>
                  )}
                </select>} />
        {informationsList?.map((e) => {
          return infoTypeSelector(e)
        }
        )}
        <Structure id="description" title={t("Description")} component={() => <TextArea name="description" defaultValue={informations.description} className="border block w-full sm:text-sm border-gray-300 rounded-md px-3"/>} />
        {/* { pipelineList ?? onChange={(e) => handleChange("description", e)} */}
        <Structure id="pipeline" title={t("Pipeline")} component={() =>
          <div className="w-60">
            <select
              // name={(id ? id : name)}
              // id={(id ? id : name)}
              className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"
              onChange={SelectPipeline}
              value={pipelineId}
            >
              <option key={""} value={""} style={{ fontSize: "14px", padding: "0 10px" }}></option>
              {pipelineList.map(e =>
                <option key={e.id} value={e.id} style={{ fontSize: "14px", padding: "0 10px" }}>{e.label}</option>
              )}
            </select>
          </div>} />
        {pipelineId ?
          <>
            <Structure id="pipeline start" title={t("Pipeline Start")} component={() =>
              <div className="w-60">
                <select
                  className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"
                  onChange={(p) => (setPipelineStart(p.target.value))}
                  value={pipelineStart}
                >
                  <option key={""} value={""} style={{ fontSize: "14px", padding: "0 10px" }}></option>
                  {pipelineStages.map(e =>
                    <option key={e.id} value={e.id} style={{ fontSize: "14px", padding: "0 10px" }}>{e.label}</option>
                  )}
                </select>
              </div>} />
            <Structure id="pipeline end" title={t("Pipeline End")} component={() =>
              <div className="w-60">
                <select
                  className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"
                  onChange={(p) => {setPipelineEnd(p.target.value)}}
                  // onClick={() => saveBuffer()}
                  value={pipelineEnd}
                >
                  <option key={""} value={""} style={{ fontSize: "14px", padding: "0 10px" }}></option>
                  {pipelineStages.map(e =>
                    <option key={e.id} value={e.id} style={{ fontSize: "14px", padding: "0 10px" }}>{e.label}</option>
                  )}
                </select>
              </div>} />
          </>
          : <></>}
        {/* } */}
        <Structure id="timedForm" title={t("Timed Form")} component={() =>
          <div className="w-full">
            <InfoTimeInputs value={time} setValue={setTime}/>
          </div>} />
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
        <div className="p-5 pt-2">
          <div className="flex justify-between">
            {id !== "new" ? <button
              type="button"
              className={"ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-400 enabled:hover:bg-red-500 disabled:opacity-50" + (candidature.enabled ? " cursor-not-allowed opacity-50" : "")}
              onClick={() => { !candidature.enabled && setDeleteButton(true) }}
              disabled={!userRights.remove_forms}
            >
              {t("Delete")}
            </button> : <div></div>}
            {deleteButton && <DeleteModal title={"Delete this form"} detail={"Are you sure to delete this form ? Make sure to clean Hubspot if this form was used."} cancel={() => setDeleteButton(false)} accept={() => { setDeleteButton(false); DeleteForm() }}></DeleteModal>}
            {id !== "new" ? <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-md text-black enabled:hover:bg-slate-200 disabled:opacity-50"
              onClick={() => { navigator.clipboard.writeText(JSON.stringify(candidature)); setCopyButton(true) }}
              disabled={!userRights.create_forms}
            >
              {t("Copy")}
            </button> : <></>}
            {copyButton && <InfoModal tilte={"Form copied"} detail={"This form has been copied to your clip board. You can use the button situated in Home."} onclick={() => setCopyButton(false)} />}
            <button
              type="submit"
              className={"ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 enabled:hover:bg-blue-600 disabled:opacity-50" + (uploading ? " cursor-wait opacity-50" : "")}
              // onClick={(e) => { !uploading && formSave(e) }}
              disabled={!userRights.informations}
            >
              {t("Save")}
            </button>
          </div>
        </div>
      </form>
    </Card> : <FakeCard />}
  </Center1Column>)

}

export default withTranslation()(Informations);