import FullLoader from './../Animation/FullLoader';

export default function Refresh({ loading, disabled, className, ...newProps }) {
    return (<button
        disabled={loading || disabled}
        className={disabled ? "inline-flex items-center border border-transparent opacity-50 cursor-not-allowed" :
            (className ? className :
                (loading ? "inline-flex items-center border border-transparent hover:text-white cursor-not-allowed" : "inline-flex items-center border border-transparent text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"))}
        {...newProps}
    >
        <svg xmlns="http://www.w3.org/2000/svg" fill="#FEFEFE" viewBox="0 0 24 24" strokeWidth="1.5" stroke="black" class="size-6" width="24" height="24" className={loading && "clockwise"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
    </button>)
}