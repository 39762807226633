import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Step from "../../../../components/Blank/Steps"
import Card from "../../../../components/Card/Card";
import Warning from "../../../../components/Card/Warning";

function DesignerStepper({ t, layout, candidature, save, steps, changeStep, candidatureSetup, setChange, moveStep }) {
    const [doubleClicked, setDoubleCliked] = useState(null);
    const [curentIndex, setCurentIndex] = useState(null);
    const [warning, setWarning] = useState(false);

    useEffect(() => {
        setCurentIndex(changeStep(undefined));
        setDoubleCliked(-1);
    }, [])

    function removeStepButton() {
        if (layout.length > 0) {
            setWarning(true);
        } else {
            removeStep(true);
        }
    }

    function stepClick(e, index) {
        if (candidature.Step && candidature.Step.length) {
            candidature.Step.forEach((step, index) => {
                step.name = steps[index].name;
            })
        }
        if (e.detail > 1) {
            setDoubleCliked(index);
        } else if (e.detail === 1) {
            if (index !== doubleClicked) {
                setDoubleCliked(-1);
            }
            changeStep(index);
            setCurentIndex(index);
        } else {
            setDoubleCliked(-1);
        }
    }

    function removeStep(check) {
        if (check) {
            candidature.Step.splice(curentIndex, 1);
            if (curentIndex > 0) {
                changeStep(curentIndex - 1);
                setCurentIndex(curentIndex - 1);
            }
            candidatureSetup();
            save();
        }
        setWarning(false);
        // setChange(c => c > 1 ? 1 : c + 1);;
    }

    return <>
        {warning &&
            <Warning onclick={removeStep} />
        }
        < div className="w-full flex flex-row" >
            <div className="w-full h-full">
                <Card noPadding>
                    <Step save={save} steps={steps} currStep={curentIndex} onClick={stepClick} input={doubleClicked} handleRemoveStep={removeStepButton} handleAddStep={() => stepClick({ "detail": 1 }, steps.length)} handleMoveStep={moveStep} stepJson={candidature} />
                </Card>
            </div>
        </div >
    </>
}

export default withTranslation()(DesignerStepper);