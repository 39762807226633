import React, { useEffect, useReducer, useState } from "react";
import { withTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import Container from './../../../components/DragNDrop/MainDND';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { GenerateLightForms, formData, moveDesignerSteps, tenantData, updateDesignerSteps } from "../../../utils/requests/cosmo";
import Full3Columns from "../../../layout/Page/Full page/3columns";
import FakeCard from "../../../components/Fake/FakeCard";
import FakeList from "../../../components/Fake/FakeList";
import ChoiceModal from "../../../components/Popup/choiceModal";
import FullLoader from "../../../components/Animation/FullLoader";

const beforeUnloadHandler = (event) => {
    event.preventDefault();
    event.returnValue = true;
  };

function Designer({ t }) {
    const { reset } = useForm();
    const [candidature, setCandidature] = useState(null);
    const [data, setData] = useState(null);
    const [, setRefresh] = useReducer(x => x + 1, 0)
    const [curentIndex, setCurentIndex] = useState(null);
    let { id } = useParams();
    const [change, setChange] = useState(1);
    const [loading, setLoading] = useState(true);
    const [upToDate, setUpToDate] = useState(false);
    const [theme, setTheme] = useState(null);
    const [payment, setPayment] = useState(null);
    const [updateWait, setUpdateWait] = useState(false);
    const [conflict, setConflict] = useState(false);

    function Change() {
        if (change > 1) {
            setChange(1);
        } else {
            setChange(c => c + 1);
        }
    }

    useEffect(() => {
        tenantData().then(tenantdata => {
            setTheme(tenantdata.theme);
            setPayment(tenantdata.payment);
        })
    }, [])

    useEffect(() => {
        setLoading(true);
        setUpdateWait(false);
        setUpToDate(false);
        setConflict(false)
        formData(id).then(candidature => {
            let errors = []
            if (candidature.ErrorsId) {
                candidature.ErrorsId.forEach(err => {
                    errors.push(err.slice(0, err.lastIndexOf(':')))
                })
            }
            candidature.Step.forEach(step => {
                step.questions.forEach(quest => {
                    if (errors.includes(quest.name)) {
                        quest.error = true
                    }
                })
            })
            if (candidature && candidature.Step && candidature.Step.length > 0) {
                // var tmp = candidature.Step[0]
                // candidature.Step.forEach((e, i) => {
                //     if (i !== 0) {
                //         tmp.questions = tmp.questions.concat(e.questions)
                //     }
                // });
                // setData(tmp);
                setData(candidature.Step[0]);
            } else {
                setData({ "name": "", "questions": [] });
                candidature.Step = [{ "name": "", "questions": [] }];
            }
            setCurentIndex(0);
            setCandidature(candidature);
            setUpToDate(true);
            setLoading(false);
        })
    }, [id]);


    useEffect(() => {
        if (data) {
            reset({ name: data.name });
        }
    }, [data, reset])

    function changeStep(index) {
        if (index === undefined) {
            return curentIndex
        }
        if (index !== curentIndex) {
            conditionalListCleanup()
            // save();
            // if (index >= candidature.steps.length) {
            if (index >= candidature.Step.length) {
                // setData(null)
                setData({ "name": "", "questions": [] });
                // candidature.steps.push({ "name": "", "hs_pipeline": "", "questions": [] });
                candidature.Step.push({ "name": "", "questions": [] });
                setCandidature(candidature);
                conditionalListCleanup()
                save();
            }
            else {
                // setData(null)
                // setData(candidature.steps[index]);
                setData(candidature.Step[index]);
            }
            setCurentIndex(index);
            Change();
        }
    }

    useEffect(() => {
        if (upToDate !== "loading" && updateWait === true) {
            setUpdateWait(false)
            conditionalListCleanup()
            save()
        } else if (upToDate === "loading") {
            window.addEventListener("beforeunload", beforeUnloadHandler);
        } if (upToDate !== "loading") {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [upToDate, updateWait])

    function conditionalListCleanup() {
        console.log("conditionalListCleanup")
        for (let i = 0; i < candidature.Step.length; i++) {
          const step = candidature.Step[i];
          for (let j = 0; j < step.questions.length; j++) {
            const question = step.questions[j];
            if (question.conditionalList && typeof question.conditionalList === "object" && question.conditionalList.length > 0) {
              for (let i = 0; i < question.conditionalList.length; i++) {
                const comp = question.conditionalList[i];
                if (comp.cond && typeof comp.cond !== "object" && comp.cond !== null) {
                    comp.cond = [comp.cond];
                } else if (comp.cond && typeof comp.cond === "object" && comp.cond !== null && comp.cond.length > 0 && typeof comp.cond[0] === "object") {
                    comp.cond = comp.cond[0];
                }
                if (comp?.And !== null && comp?.And?.cond != null) {
                    conditionalListCleanupRec(comp.And)
                }
              }
            }
          }
        }
      }

  function conditionalListCleanupRec(comp) {
    //   console.log(comp)
    if (comp.cond && typeof comp.cond !== "object" && comp.cond !== null) {
      comp.cond = [comp.cond];
    }
    if (comp?.And !== null && comp?.And?.cond != null) {
      conditionalListCleanupRec(comp.And)
    }
  }

    function save() {
        if (upToDate !== "loading") {
            setUpToDate("loading");
            updateDesignerSteps(candidature).then(res => {
                if (res.ok) {
                    setUpToDate(true);
                    return res.json();
                } else {
                    setUpToDate(false);
                }
                GenerateLightForms()
            }).then(res => {
                if (res) {
                    if (res === "REFRESH") {
                        setUpToDate(false);
                        setConflict(true);
                    } else {
                        candidature.modificationStepDate = JSON.parse(res).modificationStepDate;
                    }
                }
            }
            )
        } else {
            setUpdateWait(true)
        }
    }

    function moveStep(oldPosition, currPos) {
        moveDesignerSteps(oldPosition, currPos, candidature.id).then(res => {
            if (res) {
                GenerateLightForms()
                setCandidature(res)
                setData(res.Step[oldPosition]);
                setCurentIndex(oldPosition);
                setUpToDate(true);
                Change();
            } else {
                setUpToDate(false);
            }
        })
    }

    return (loading && payment) ? <Full3Columns left={<FakeCard />} middleHeader={<FakeCard />}><FakeList length={3} component={FakeCard} /></Full3Columns> : (data && change && candidature ?
        <>
        <DndProvider key={"dndprovider"} backend={HTML5Backend}>
            <Container key={"dndcontainer"} theme={theme} scroll={setRefresh} data={data} changeStep={changeStep} candidature={candidature} save={save} upToDate={upToDate} loading={loading} curentIndex={curentIndex} payment={payment} moveStep={moveStep} />
        </DndProvider>
        {conflict && <ChoiceModal title={"Conflict"} detail={"Someone modified this form, you need to refresh it to be up to date."} accept={() => window.location.reload()} cancel={() => setConflict(false)}></ChoiceModal>}
        </>
        : <><div>{t("Loading")}</div><FullLoader/></> //add spiner
    )
}

export default withTranslation()(Designer);