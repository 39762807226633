import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import Card from "../../../components/Card/Card";
import FakeCard from "../../../components/Fake/FakeCard";
import Center1Column from "../../../layout/Page/Center/1column";
import { formData, getCurentUser, UpdateFormVariables } from "../../../utils/requests/cosmo";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import VariablesPopup from "../../../components/Popup/variablesPopup";
import DeleteModal from "../../../components/Popup/deleteModal";
import Refresh from "../../../components/Buttons/Refresh";

function Variables({ t }) {
  const [loading, setLoading] = useState(false);
  const [candidature, setCandidature] = useState({});
  const [userRights, setUserRights] = useState({})
  const [variables, setVariables] = useState([])
  // const [variables, setVariables] = useState([{ id: "948629f6-a395-47fb-95fe-6f30fcd7d788", fieldType: "text", name: "classe", value: "Américaine" }, { id: "7a51187a-47cd-4f77-babd-f8ebcbe8dc3c", fieldType: "number", name: "Prix", value: "20000" }])
  const [variableId, setVariableId] = useState("new")
  const [popupOpen, setPopupOpen] = useState(false)
  const [popupError, setPopupError] = useState(false)

  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getCurentUser().then(curentUser => {
      setUserRights(curentUser.rights)
      if (id === "new") {
        setCandidature({});
        setVariables([]);
        setLoading(false);
        setPopupOpen(false);
      } else {
        formData(id).then(cand => {
          setCandidature(cand);
          if (cand.Variables) {
            setVariables(cand.Variables.map((variableLoc) => {
              if(variableLoc.fieldType === "price"){
                variableLoc.amountUse = cand.Step.reduce((partialSum, step) => partialSum + step.questions.filter(q => q.object === "Paybox" && q.options.find(opt => opt.label === "Variable" && opt.value === variableLoc.id)).length, 0)
              } else {
              variableLoc.amountUse = cand.Step.reduce((partialSum, step) => partialSum + step.questions.filter(q => q.fieldType === "Variable" && q.objectId === variableLoc.id).length, 0)
              }
              return variableLoc;
            }))
          } else {
            setVariables([])
          }
          setPopupOpen(false);
          setLoading(false);
        })
      }
    })
  }, [id]);

  function removeVariable(varId) {
    const stepStr = JSON.stringify(candidature.Step)
    const pos = stepStr.indexOf(varId)
    if (pos === -1) {
      const locoVariables = variables.filter(v => v.id !== varId)
      setVariables(locoVariables)
      save(locoVariables)
    } else {
      setVariableId(varId)
      setPopupError(true)
    }
  }

  function superRemove(id) {
    //TODO remove variable from anywhere in form
  }

  function save(variables) {
    setLoading(true);
    UpdateFormVariables(variables, id).then(() => {
      setVariables(variables)
      setLoading(false);
    })
  }

  function reload() {
    setLoading(true);
    formData(id).then(cand => {
      setCandidature(cand);
      if (cand.Variables) {
        setVariables(cand.Variables)
      } else {
        setVariables([])
      }
      setPopupOpen(false);
      setLoading(false);
    })
  }

  return (<Center1Column>
    {id ? <Card noPadding>
      <div className="flex justify-between px-8 py-3">
        <h1 className="text-xl font-semibold text-gray-900">{t("Variables")}</h1>
        <Refresh loading={loading} onClick={reload}/>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-700">
              {t("A list of all the variables that can be used in this form.")}
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              onClick={() => { setVariableId("new"); setPopupOpen(true) }}
            >
              {t("Add variable")}
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="">
              <table className="min-w-full divide-y divide-gray-300">
                {/* <colgroup>
                  <col className="w-1/8" />
                  <col className="w-1/4" />
                  <col className="" />
                  <col className="w-1/12" />
                </colgroup> */}
                <thead>
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="group inline-flex cursor-default">
                        {t("Type")}
                        {/* <button className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                          <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
                        </button> */}
                      </div>
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      <div className="group inline-flex cursor-default">
                        {t("Name")}
                      </div>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="group inline-flex cursor-default">
                        {t("Value")}
                      </div>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="group inline-flex cursor-default">
                        {t("Utilisation")}
                      </div>
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {variables.map((varia) => (
                    <tr key={varia.id}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{varia.fieldType}</td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {varia.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{varia.value}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{varia.amountUse}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0">
                        <button className="align-top text-primary-600 hover:text-primary-900" onClick={() => { setVariableId(varia.id); setPopupOpen(true) }}>
                          <PencilAltIcon className="h-5 w-5 py-auto" aria-label="modify" />
                          {/* {t("Edit")} */}
                        </button>
                        <button className={varia.amountUse? "pl-4 align-top text-gray-400 cursor-not-allowed" : "pl-4 align-top text-primary-600 hover:text-primary-900 cursor-pointer"} disabled={varia.amountUse>0} onClick={() => removeVariable(varia.id)}>
                          <TrashIcon className="h-5 w-5 py-auto" aria-label="errase" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {popupOpen && <VariablesPopup id={variableId} setId={setVariableId} cancel={() => setPopupOpen(false)} variables={variables} saveVariables={(v) => {setVariables(v);setPopupOpen(false);save(v)}} removeVariable={removeVariable}/>}
      {popupError && <DeleteModal cancel={() => setPopupError(false)} accept={() => {superRemove(variableId)}} title={t("Remove ") + variables[variables.findIndex(v => v.id === variableId)].name} detail={t("This variable is used in this form, removing it will remove it anywhere in this form.")} />}
    </Card> : <FakeCard />}
  </Center1Column>)

}

// {
//   public string id { get; set; }

//   public string fieldType { get; set; }

//   public string name { get; set; }

//   public string value { get; set; }
// }

export default withTranslation()(Variables);